$(document).ready(function(){
    //- debounce function (for scroll event)
    function debounce(a,b,c){var d;return function(){var e=this,f=arguments;clearTimeout(d),d=setTimeout(function(){d=null,c||a.apply(e,f)},b),c&&!d&&a.apply(e,f)}}

    $('body').removeClass('no-js').addClass('js');


    /**
     * [navComponent]
     * @type {Object}
     */
    var navComponent = {
        el: false,
        elYPos : false,
        menu: false,
        sticked: false,
        open: false,

        /**
         * [Init | Stock quelques variables et attach les events listener]
         */
        init: function(){
            this.el = $('#navigationPrimary'); //- Header with .header-1 and .header-navigation
            this.elYPos = $(this.el).height(); //- Y bottom position of Header / this.el
            this.menu = $('#menu'); //- Menu
            this.navicon = $('#navicon'); //- Hamburger button
            if($(window).width() > 1024) $('body').css('padding-top', $(this.el).height());
            this.attachEvent(); //- Attach events to DOM Elements

            //- Specific script if user is on homepage, hash exists and section target exists too, we give active class to nav item
            if (this.isHomePage() && window.location.hash !== '') {
                $(window.location.hash).length ? $('a.nav-item[href="' + window.location.hash  + '"]').addClass('active') : null;
            }
        },

        attachEvent: function(){
            $(this.navicon).on('click', function(e){
                !this.open && this.isMobile() && !$(this.navicon).hasClass('is-open') ? this.showNav() : null;
                this.open && this.isMobile() && $(this.navicon).hasClass('is-open') ? this.hideNav() : null;
                this.open = !this.open;
            }.bind(this));


            var checkSticky = debounce(function(){
                // console.log('scrollDebounce called');
                $(window).scrollTop() > navComponent.elYPos && !navComponent.sticked && !navComponent.isMobile() ? navComponent.setSticky() : null;
                $(window).scrollTop() < (navComponent.elYPos * 2) && navComponent.sticked && !navComponent.isMobile() ? navComponent.setUnSticky() : null;
            }, 25);

            window.addEventListener('scroll', checkSticky);



        },

        setSticky : function(){
            if (!this.sticked && !this.isMobile()) {
                $(this.el).addClass('sticky');
                this.sticked = true;
            }
        },

        setUnSticky: function(){
            if (this.sticked && !this.isMobile()) {
                $(this.el).removeClass('sticky');
                this.sticked = false;
            }
        },


        /** [show | Only Mobile | Show navigation with animation] */
        showNav: function(){
            $(this.navicon).addClass('is-open').attr('aria-expanded','true');
            $(this.menu).removeClass('is-closed').fadeIn(250);
            // show contact & socials links
            if(contactMenu === undefined) var contactMenu = $('.footer-contact').html();
            if(socialMenu === undefined) var socialMenu = $('.social-links').html();
            if($('.mobile-menu-social').size() == 0) {
                $(this.menu).append(contactMenu);
                $(this.menu).append('<div class="mobile-menu-social">'+socialMenu+'</div>');
            }
        },

        /** [hide | Only Mobile | Hide navigation with animation] */
        hideNav : function(){
            $(this.navicon).removeClass('is-open').attr('aria-expanded','false');
            $(this.menu).addClass('is-closed').fadeOut(250);
            // hide contact & socials links
        },

        /** [isMobile | Check if user are on mobile] */
        isMobile: function(){
            return $(window).width() < 1025 ? true : false;
        },

        /** [isOpen | Only mobile | Check if navigation is open ] */
        isOpen: function(){
            return $(this.menu).hasClass('closed');
        },

        /** [isHomePage | @return if user is on homepage] */
        isHomePage: function(){
            return $('.page').hasClass('homepage');
        },

        /**
         * [scrollToSection | Scroll to target section if exist | /!\ Only on Homepage]
         * @param  {[string]} href [link href, `#section`]
         * @param  {[DOM Element[jQuery obj]]} link [nav item clicked]
         */
        scrollToSection: function(href, link){
            var target = $(href);

            //- If target section exists on homepage, else do nothing
            if (target.length) {
                //- If we are on mobile, close nav, else continue
                this.isMobile() ? function(){ this.hideNav(); this.open = false; }.bind(this)() : null;

                //- If is mobile -> nav height is lower than full header height
                var headerHeight = this.isMobile() ? 90 : $(this.el).height();
                $('html, body').animate({
                    scrollTop: (target.offset().top - headerHeight)
                }, 500);

                //- Switch active class to good nav link
                $(this.menu).find('a').removeClass('active');
                link.addClass('active');

            }
        }
    };

    // $('*').each(function(){
    //     $(this).width() > 320 ? console.log($(this)) : null;
    // })


    //- If navigation are present on Document, initialize navComponent
    if ($('#navigationPrimary').length) {
        navComponent.init();
    }

    /**
     * Mobile menu is open on page load
     */
    
    if(navComponent.isMobile()) {
        navComponent.showNav();
    }


    /**
     * Slick Carousel Component
     */
     if ($('.slider.fade').length > 0) {
        $('.slider.fade').slick({
            dots: true,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 2500,
            arrows:true
        });    
     }
    


    /**
     * Modaal AA accessible popin script
     */
     if ($('.modaal-ajax').length > 0) {
        $('.modaal-ajax').modaal({
            type: 'ajax'
        });    
     }
    


    /**
     * Custom select
     */
    $(function() {
        
        if ($('select.custom').length > 0) {
		  $('select.custom').customSelect();
        }
	});


});
